import _taggedTemplateLiteral from "/Users/teerapatcheung/Desktop/0-Project/logistics/logistics_metronic_vue/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9;

import gql from "graphql-tag";
export var ONE_DRIVE_API = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  mutation($_id: ID) {\n    oneDriveApi(_id: $_id) {\n      isSuccess\n      message\n      data\n    }\n  }\n"])));
export var DELETE_LOT = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  mutation($_id: ID) {\n    delete_lot(_id: $_id) {\n      isSuccess\n      message\n    }\n  }\n"])));
export var CREATE_LOT = gql(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  mutation($input: LotInput) {\n    newLot(input: $input) {\n      isSuccess\n      is_error\n      message\n    }\n  }\n"])));
export var UPDATE_LOT = gql(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  mutation($input: LotInput) {\n    update_lot(input: $input) {\n      isSuccess\n      is_error\n      message\n    }\n  }\n"])));
export var GET_LOT_LIST = gql(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  query($pageSize: Int!, $page: Int!, $search: String) {\n    systemLotList(pageSize: $pageSize, page: $page, search: $search) {\n      recordsData {\n        recordsFiltered\n      }\n      records {\n        _id\n        lotId\n        lotName\n        ship_by\n        lotUrl\n        type_name\n        send_out_china\n        send_to_warehouse_th\n        is_processing\n        updated_at\n        numOfTransport\n      }\n    }\n  }\n"])));
export var GET_LOT = gql(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  query($id: Int) {\n    systemLot(id: $id) {\n      isSuccess\n      message\n      record {\n        _id\n        lotId\n        lotName\n        ship_by\n        lotUrl\n        send_out_china\n        send_to_warehouse_th\n        is_processing\n        type\n        transportList {\n          _id\n          transportId\n          transportNumber\n          amount\n          userId\n          user {\n            userId\n            username\n          }\n          in_order {\n            orderId\n            orderCode\n          }\n          file_tracking\n          userLevelPrice {\n            userLevelDetailName\n            userLevelDetailPriceKg\n            userLevelDetailPriceCube\n            userLevelDetailShipping\n          }\n          trackingPrice {\n            priceName\n            priceValue\n          }\n          size {\n            weight\n            long\n            height\n            width\n          }\n          lotId\n          no\n          guarantee\n          timeline {\n            send_to_warehouse_china\n            send_out_china\n            send_to_warehouse_th\n            payshipping\n            sent_out_to_customer\n          }\n        }\n      }\n    }\n  }\n"])));
export var ADD_LOT_TRACKING = gql(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  mutation($input: LotTransportInput) {\n    add_transport_to_lot(input: $input) {\n      isSuccess\n      message\n      error_list {\n        path\n        message\n      }\n    }\n  }\n"])));
export var REMOVE_LOT_TRACKING = gql(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  mutation($lotId: Int, $transportId: Int) {\n    remove_transport_lot(lotId: $lotId, transportId: $transportId) {\n      isSuccess\n      message\n    }\n  }\n"])));
export var GET_FIND_TRACKINGS = gql(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n  query($transportId: Int, $transportNumber: String) {\n    findTransport(\n      transportId: $transportId\n      transportNumber: $transportNumber\n    ) {\n      isSuccess\n      data {\n        _id\n        transportId\n        transportNumber\n        amount\n        ship_by\n        user {\n          userId\n          username\n        }\n        in_order {\n          orderId\n          orderCode\n        }\n        file_tracking\n        userLevelPrice {\n          userLevelDetailName\n          userLevelDetailPriceCube\n          userLevelDetailPriceKg\n          userLevelDetailShipping\n        }\n        size {\n          weight\n          long\n          height\n          width\n        }\n        logisticsServicePrice {\n          _id\n          priceName\n          priceValue\n        }\n        trackingPrice {\n          priceName\n          priceValue\n        }\n        lotId\n        no\n        timeline {\n          send_to_warehouse_china\n          send_out_china\n          send_to_warehouse_th\n          payshipping\n          sent_out_to_customer\n        }\n        remark\n        remarkForCustomer\n        guarantee\n        status\n        statusShow\n        created_at\n      }\n    }\n  }\n"])));