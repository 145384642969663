import _taggedTemplateLiteral from "/Users/teerapatcheung/Desktop/0-Project/logistics/logistics_metronic_vue/node_modules/@babel/runtime/helpers/esm/taggedTemplateLiteral";

var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12;

import gql from "graphql-tag";
export var DELETE_USER = gql(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  mutation($id: ID!) {\n    deleteUser(_id: $id) {\n      isSuccess\n      message\n    }\n  }\n"])));
export var APPROVE_USER = gql(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  mutation($id: Int!, $input: UserInput) {\n    approveUser(id: $id, input: $input) {\n      isSuccess\n      message\n    }\n  }\n"])));
export var REJECT_USER = gql(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  mutation($id: Int!) {\n    rejectUser(id: $id) {\n      isSuccess\n      message\n    }\n  }\n"])));
export var UPDATE_USER = gql(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  mutation($input: UserInput) {\n    updateUser(input: $input) {\n      isSuccess\n      message\n      error_list {\n        path\n        message\n      }\n    }\n  }\n"])));
export var CREATE_USER = gql(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  mutation($input: UserInput) {\n    createUser(input: $input) {\n      isSuccess\n      message\n      error_list {\n        path\n        message\n      }\n    }\n  }\n"])));
export var GET_USER = gql(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  query($id: Int!) {\n    user(id: $id) {\n      userId\n      userNo\n      full_name\n      photo_url\n      username\n      password\n      line\n      email\n      phone\n      is_admin\n      userLevelId\n      roleId\n      balance\n      address {\n        _id\n        name\n        phone\n        province\n        amphoe\n        district\n        postcode\n        address\n        default\n      }\n      verify_file\n      status\n      statusShow\n      created_at\n    }\n  }\n"])));
export var GET_USERS = gql(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  query($pageSize: Int, $page: Int, $search: String, $status: Int) {\n    users(pageSize: $pageSize, page: $page, search: $search, status: $status) {\n      recordsData {\n        recordsFiltered\n      }\n      records {\n        _id\n        userId\n        userNo\n        full_name\n        photo_url\n        username\n        email\n        phone\n        is_admin\n        created_at\n        status\n        statusShow\n        updated_id_info {\n          userId\n          username\n        }\n      }\n    }\n  }\n"])));
export var SEARCH_USERS = gql(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  query($pageSize: Int, $page: Int, $search: String) {\n    searchUserList(pageSize: $pageSize, page: $page, search: $search) {\n      recordsData {\n        recordsFiltered\n      }\n      records {\n        _id\n        userId\n        username\n      }\n    }\n  }\n"])));
export var GET_USER_ADDRESS = gql(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n  query {\n    user {\n      address {\n        _id\n        name\n        phone\n        province\n        amphoe\n        district\n        postcode\n        address\n        default\n        default_sender\n      }\n    }\n  }\n"])));
export var CREATE_USER_ADDRESS = gql(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n  mutation($input: AddressInput) {\n    createUserAddress(input: $input) {\n      isSuccess\n      message\n      error_list {\n        path\n        message\n      }\n    }\n  }\n"])));
export var UPDATE_USER_ADDRESS = gql(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["\n  mutation($input: AddressInput) {\n    updateUserAddress(input: $input) {\n      isSuccess\n      message\n      error_list {\n        path\n        message\n      }\n    }\n  }\n"])));
export var DELETE_USER_ADDRESS = gql(_templateObject12 || (_templateObject12 = _taggedTemplateLiteral(["\n  mutation($userId: Int, $_id: ID) {\n    deleteUserAddress(userId: $userId, _id: $_id) {\n      isSuccess\n      message\n      error_list {\n        path\n        message\n      }\n    }\n  }\n"])));